import { ChangeEvent, useEffect, useState } from 'react';
import { Grid, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { BlankMenuItem } from '../../util/SharedStyles';
import CloseIcon from '@mui/icons-material/Close';
import { EdiStaticFileDocumentType } from '../../gql-types.generated';
import { Viewer } from '../../util/Constants';
import FilterBar from './FilterBar';

interface StaticFilesFilterBarProps {
    id?: string;
    loading: boolean;
    visible: boolean;
    viewer: Viewer | undefined;
    onClose: () => void;
    onFilterChanged: (
        filterDescription: string | undefined, 
        filterFileName: string | undefined, 
        filterDocumentType: EdiStaticFileDocumentType | undefined, 
    ) => void;
}

const EDIStaticFilesFilterBar: React.FC<StaticFilesFilterBarProps> = props => {
    const { id, loading = false, visible = false, viewer, onFilterChanged } = props;
    const [filterDescription, setFilterDescription] = useState<string | undefined>(undefined);
    const [filterFileName, setFilterFileName] = useState<string | undefined>(undefined);
    const [filterDocumentType, setFilterDocumentType] = useState<EdiStaticFileDocumentType | undefined>(undefined);
    
    const fieldsDisabled = visible && loading;

    useEffect(() => {
        // need to send out an initial fetch 
        onSearchClick();
    }, []);

    // get the list of document types for the filter dropdown
    const docTypes = Object.values(EdiStaticFileDocumentType); 

    const onSearchClick = () => {
        onFilterChanged(filterDescription, filterFileName, filterDocumentType);
    };

    const onCloseClick = () => {
        // remove any filters
        clearFilters();

        // call parent to hide bar
        props.onClose();

        // trigger a new search with filters undefined since state of filter values might not be updated yet
        onFilterChanged(undefined, undefined, undefined);
    };

    const clearFilters = () => {
        clearDescriptionFilter();
        clearFileNameFilter();
        setFilterDocumentType(undefined);
    };

    const clearDescriptionFilter = () => {
        setFilterDescription(undefined);
    };

    const clearFileNameFilter = () => {
        setFilterFileName(undefined);
    };
  
    const getDocumentTypeFilterList = () => {
        if (docTypes && docTypes.length) {
            let items = [];
            const blankItem = <BlankMenuItem key="0" value=""></BlankMenuItem>;
            const mappedItems = (
                docTypes.map((type: EdiStaticFileDocumentType) => (
                    <MenuItem
                        key={type}
                        value={type}
                        disabled={fieldsDisabled}
                    >
                        {type.toUpperCase()}
                    </MenuItem>
                ))
            );
            items.push(blankItem);
            items.push(...mappedItems);
            return items;
        }
        return null;
    };
    
    const onDescriptionFilterChange = (event: ChangeEvent<HTMLInputElement>) => {

        let nameValue = event.target.value;
        setFilterDescription(nameValue);
    };

    const onFileNameFilterChange = (event: ChangeEvent<HTMLInputElement>) => {

        let nameValue = event.target.value;
        setFilterFileName(nameValue);
    };
    
    const onDocumentTypeFilterChange = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;
        
        let currentValue = value?.length > 0 ? value as EdiStaticFileDocumentType : undefined;
        
        setFilterDocumentType(currentValue);
    };
    
    const descriptionFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear description filter"
                    onClick={clearDescriptionFilter}
                    disabled={fieldsDisabled}
                >
                    {filterDescription && filterDescription.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const fileNameFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear file name filter"
                    onClick={clearFileNameFilter}
                    disabled={fieldsDisabled}
                >
                    {filterFileName && filterFileName.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };
   
    return (
        <FilterBar id={id} visible={visible} onClose={onCloseClick} onSearch={onSearchClick}>
            <Grid item xs={2}>
                <TextField
                    itemID="formatter-filter-file-name"
                    fullWidth
                    value={filterFileName ?? ''}
                    label="File Name"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'file name', 'maxLength': 50, }}
                    InputProps={fileNameFilterProps}
                    onChange={onFileNameFilterChange}
                    autoComplete="off"
                    data-cy="formatter-filter-file-name-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    itemID="formatter-filter-description"
                    fullWidth
                    value={filterDescription ?? ''}
                    label="Description"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'description', 'maxLength': 50, }}
                    InputProps={descriptionFilterProps}
                    onChange={onDescriptionFilterChange}
                    autoComplete="off"
                    data-cy="formatter-filter-description-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={1} lg={2}>
                <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                    <InputLabel id="formatter-filter-doc-type-label">Document Type</InputLabel>
                    <Select
                        labelId="formatter-filter-doc-type-label"
                        aria-labelledby="formatter-filter-doc-type-label"
                        id="formatter-filter-doc-type"
                        value={filterDocumentType ?? ''}
                        MenuProps={{
                            'aria-label': 'document type',
                        }}
                        onChange={onDocumentTypeFilterChange}
                        data-cy="formatter-filter-doc-type"
                    >
                        {getDocumentTypeFilterList()}
                    </Select>
                </FormControl>
            </Grid>
        </FilterBar>
    );
};

export default EDIStaticFilesFilterBar;