import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { debounce } from 'lodash';
import { Tooltip } from "@mui/material";
import { useTitle } from "../../util/Common";
import { ToastSeverity, Viewer, defaultPageSize } from "../../util/Constants";
import { viewerCanAddDelete, viewerCanEdit } from "../../util/ViewerUtility";
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { EdiStaticFileModel, UpsertEdiStaticFileInput, EdiStaticFileDocumentType, RequestResult, OrderDirection, EdiStaticFileOrderByInput, GetEdiStaticFileInput } from '../../gql-types.generated';
import { 
    selectError, 
    clearError, 
    clearState, 
    selectStaticFileList, 
    selectRequestsInFlight, 
    selectStaticFilePagingResult,
    selectUpsertStaticFileStatus, 
    captureUpsertStaticFileStatus, 
    selectDeleteStaticFileStatus, 
    captureDeleteStaticFileStatus 
} from './EDIStaticFilesSlice';
import { fetchStaticFileList, upsertEdiStaticFile, deleteEdiStaticFile } from './EDIStaticFilesActions';
import ErrorMessage from '../../components/ErrorMessage';
import { MainContentBox, MainDataGridNoRowHover, DataGridListScrollBox, PageTitleToolbarGrid } from '../../util/SharedStyles';
import PageTitleBar from '../../components/PageTitleBar';
import CreateNewButton from '../../components/buttons/CreateNewButton';
import { GridActionsCellItem, GridColumns, GridRenderCellParams, GridRowModel, GridOverlay, GridSortItem, GridSortModel, GridRowParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { downloadDocument } from "../../util/Common";
import MainDataGridLoadingSkeleton from '../../components/MainDataGridLoadingSkeleton';
import NoRecordsMessage from '../../components/NoRecordsMessage';
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import EDIStaticFileDialog from "../../components/dialogs/EDIStaticFileDialog";
import FiltersButton from '../../components/buttons/FiltersButton';
import EDIStaticFilesFilterBar from "../../components/filters/EDIStaticFilesFilterBar";

interface EDIStaticFilesProps {
    viewer: Viewer | undefined;
}

const EDIStaticFiles: React.FC<EDIStaticFilesProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [contentAreaHeight, setContentAreaHeight] = useState('auto');
    const [staticFileRows, setStaticFileRows] = useState<GridRowModel[] | undefined>(undefined);
    const [selectedStaticFile, setSelectedStaticFile] = useState<EdiStaticFileModel | undefined>(undefined);
    const [isAddEditDialogReadonlyMode, setIsAddEditDialogReadonlyMode] = useState(true);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filtersCleared, setFiltersCleared] = useState(false);
    const [filterDescription, setFilterDescription] = useState<string | undefined>(undefined);
    const [filterDocType, setFilterDocType] = useState<EdiStaticFileDocumentType | undefined>(undefined);
    const [filterFileName, setFilterFileName] = useState<string | undefined>(undefined);
    const [filterCount, setFilterCount] = useState(0);
    const [debounceOn, setDebounceOn] = useState(false);
    const [sortFirstLoad, setSortFirstLoad] = useState(true);
    const [waitForSort, setWaitForSort] = useState(false);
        
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'fileName',
            sort: 'asc',
        },
    ]);
    const [serverSortModel, setServerSortModel] = useState<EdiStaticFileOrderByInput[] | undefined>([{
        fileName: OrderDirection.Asc
    } as EdiStaticFileOrderByInput]);

    const pageSize = defaultPageSize;

    const staticFileList = useAppSelector(selectStaticFileList);
    const requestsInFlight = useAppSelector(selectRequestsInFlight);
    const staticFilesPagingResult = useAppSelector(selectStaticFilePagingResult);
    const upsertStaticFileStatus = useAppSelector(selectUpsertStaticFileStatus);
    const deleteStaticFileStatus = useAppSelector(selectDeleteStaticFileStatus);
    const error = useAppSelector(selectError);
    const canEdit = viewerCanEdit(viewer);
    const canAddOrDelete = viewerCanAddDelete(viewer);

    useTitle("EDI Static Resources");

    const debounceOnSortingChanged = useRef(
        debounce((servSortModel) => {
            onServerSortModelChanged(servSortModel);
        }, 1000)
    ).current;

    useEffect(() => {
        return () => {
            debounceOnSortingChanged.cancel();
        };
    }, [debounceOnSortingChanged]);

    useEffect(() => {
        // we have content, so lets properly size that content area        
        const titleHeight = document.getElementById('static-files-title-comp')?.clientHeight || 0;
        // if filters are open, include that in the calculation
        let filterBarHeight = 0;
        if (filtersOpen) {
            filterBarHeight = document.getElementById('static-files-filter-bar')?.clientHeight || 0;
        }
        let totalHeaderAreaHeight = titleHeight + filterBarHeight;
        if (totalHeaderAreaHeight > 0)
            setContentAreaHeight(`calc(100% - ${totalHeaderAreaHeight}px)`);
    }, [staticFileList, filtersOpen]);

    useEffect(() => {
        if (deleteStaticFileStatus?.result === RequestResult.Success) {
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteStaticFileStatus.message as string,
                severity: ToastSeverity.Success
            }));
        } else if (deleteStaticFileStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteStaticFileStatus.message as string);
        }
    }, [deleteStaticFileStatus?.result])

    useEffect(() => {
        if (upsertStaticFileStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertStaticFileStatus.message as string,
                severity: ToastSeverity.Success
            }));
            dispatch(captureUpsertStaticFileStatus());
            onAddEditDialogClose();
        }
    }, [upsertStaticFileStatus?.result])

    useEffect(() => {
        if (staticFileList) {
            let numFilters = 0;
            if (filterFileName && filterFileName.length > 0) {
                numFilters += 1;
            }
            if (filterDescription && filterDescription.length > 0) {
                numFilters += 1;
            }
            if (filterDocType && filterDocType.length > 0) {
                numFilters += 1;
            }
            setFilterCount(numFilters);
        }
    }, [staticFileList, filterFileName, filterDescription, filterDocType]);

    useEffect(() => {
        setStaticFileRows(getStaticFileRows());
        if (!staticFileList && requestsInFlight > 0) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [staticFileList, requestsInFlight]);

    const getSortRefreshInput = () => {
        return {
            after: undefined,
            limit: pageSize,
            description: filterDescription,
            documentType: filterDocType,
            fileName: filterFileName,
            order: serverSortModel
        } as GetEdiStaticFileInput
    }

    useEffect(() => {
        if (!sortFirstLoad) {
            const sortRefreshData = getSortRefreshInput();
            if (debounceOn !== true) {
                onServerSortModelChanged(sortRefreshData);
            } else {
                debounceOnSortingChanged(sortRefreshData);
            }
        } else {
            setSortFirstLoad(false);
        }
    }, [serverSortModel]);

    const loadPage = (endEdge: string | undefined) => {
        dispatch(fetchStaticFileList({
            after: endEdge,
            limit: pageSize,
        }));
    };

    const handlePageLoad = () => {
        if (!staticFilesPagingResult) {
            return;
        }
        if (!staticFilesPagingResult.cursor?.nextPage) {
            return;
        }

        loadPage(staticFilesPagingResult.cursor.nextPage);
    };

    const onFiltersClick = () => {
        // Show/hide filters bar
        setFiltersOpen(!filtersOpen);
        // reset filters cleared to false since not clicking close button on bar at this point
        setFiltersCleared(false);
    };

    const onAddClick = () => {
        dispatch(clearError());
        if (selectedStaticFile) {
            setSelectedStaticFile(undefined);
        }
        setIsAddEditDialogReadonlyMode(false);
        setOpenModify(true);
    };

    const onAddEditDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeleteStaticFileStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        dispatch(clearError());
        setSelectedStaticFile(undefined);
        setIsAddEditDialogReadonlyMode(true);
        dispatch(fetchStaticFileList());
    };

    const onAddEditDialogSave = (staticFileDetails: UpsertEdiStaticFileInput) => {
        dispatch(upsertEdiStaticFile(staticFileDetails));
    };

    const onDeleteDialogConfirm = (id: string) => {
        dispatch(deleteEdiStaticFile(id));
    };

    const getServerSortEntryFromGridSortEntry = (entry: GridSortItem) => {
        let newModel = {} as EdiStaticFileOrderByInput;
        switch (entry.field) {
            case 'description':
                newModel.description = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            case 'documentType':
                newModel.documentType = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            case 'fileName':
                newModel.fileName = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
        }
        return newModel;
    };

    const createdAndSetServerSortModel = (model: GridSortModel) => {
        if (model && model.length > 0) {
            let newArray = [] as EdiStaticFileOrderByInput[];
            let i = 0;
            for (i; i < model.length; ++i) {
                newArray.push(getServerSortEntryFromGridSortEntry(model[i]));
            }
            setServerSortModel(newArray);
        } else {
            setServerSortModel(undefined);
        }

    };

    const onSortModelChange = (model: GridSortModel) => {
        createdAndSetServerSortModel(model);
        setSortModel(model);
    };

    const onServerSortModelChanged = (refreshInput: GetEdiStaticFileInput) => {
        dispatch(clearState());
        dispatch(fetchStaticFileList(refreshInput));
    };

    const onFilterBarClose = () => {
        // set filters as cleared
        setFiltersCleared(true);
        setFilterCount(0);
        // hide filter bar
        setFiltersOpen(false);
    };

    const refreshFilters = (descriptionFilter: string | undefined, fileNameFilter: string | undefined, docTypeFilter: EdiStaticFileDocumentType | undefined) => {
        setFilterDescription(descriptionFilter);
        setFilterDocType(docTypeFilter);
        setFilterFileName(fileNameFilter);

        dispatch(clearState());

        // filters changed so refresh the list
        dispatch(fetchStaticFileList({
            after: undefined,
            limit: pageSize,
            description: descriptionFilter,
            documentType: docTypeFilter,
            fileName: fileNameFilter,
            order: serverSortModel
        }));
    };

    const getSelectedRow = useCallback((rowId: string) => () => {
        if (rowId && staticFileList?.length) {
            let staticFile = staticFileList.find(file => file.id === rowId);
            return staticFile;
        }
        return undefined;
    }, [staticFileList]);

    const editHandler = useCallback((rowId: string) => () => {
        let staticFile = getSelectedRow(rowId);
        if (staticFile) {
            setSelectedStaticFile(staticFile);
            setIsAddEditDialogReadonlyMode(false);
            setOpenModify(true);
        }
    }, [getSelectedRow]);

    const deleteHandler = useCallback((rowId: string) => () => {
        let staticFile = getSelectedRow(rowId);
        if (staticFile) {
            setSelectedStaticFile(staticFile);
            setOpenDelete(true);
        }
    }, [getSelectedRow]);

    const onDownload = (fileString: string, fileName: string, fileType?: EdiStaticFileDocumentType) => {
        let mimeType = fileType === EdiStaticFileDocumentType.Sef ? "text/plain" : "text/xml";
        downloadDocument(fileString, fileName ?? "Static-Resource", mimeType, true);
    };

    const downloadHandler = useCallback((rowId: string) => () => {
        let staticFile = getSelectedRow(rowId);
        if (staticFile) {
            // because coming from callback, need to treat return value as function
            let document = staticFile()?.documentString as string;
            let fileName = staticFile()?.fileName as string;
            let type = staticFile()?.documentType;
            if (document !== undefined && document !== null) {
                onDownload(document, fileName, type);
            }
        }
    }, [getSelectedRow]);

    const getStaticFileRows = () => {
        if (staticFileList && staticFileList.length > 0) {
            return staticFileList.map((staticFile: EdiStaticFileModel) => {
                const { fileName, description, documentString, id, documentType } = staticFile;
                let hasDoc = documentString && documentString.length;

                return {
                    _raw: staticFile,
                    id,
                    fileName,
                    description,
                    documentType,
                    hasDocument: hasDoc,
                }

            }) as GridRowModel[];
        }
    };

    const staticFileColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                headerName: 'FILE NAME',
                field: 'fileName',
                flex: 1,
                sortable: true,
                cellClassName: "ediDataGridCellFirstChild"
            },
            {
                headerName: 'DESCRIPTION',
                field: 'description',
                flex: 1,
                sortable: true,
                cellClassName: "ediDataGridWrapCellContent-alwaysLeft"
            },
            {
                field: 'documentType',
                headerName: 'TYPE',
                flex: 1,
                sortable: true,
            },
            {
                field: 'hasDocument',
                headerName: 'RESOURCE',
                minWidth: 200,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    const hasDoc = value;

                    return (
                        <GridActionsCellItem
                            label="Download"
                            color="primary"
                            disabled={!hasDoc}
                            onClick={downloadHandler(params.row.id)}
                            icon={<Tooltip title="Download"><DownloadIcon /></Tooltip>}
                        />
                    );
                }
            },
            {
                field: 'actions',
                type: 'actions',
                sortable: false,
                resizable: false,
                minWidth: 100,
                headerAlign: 'center',
                align: 'right',
                hide: !canEdit, // hide column for reader role
                cellClassName: "ediDataGridCellLastChild",
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit Resource"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete Resource"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        hidden={!canAddOrDelete}
                        onClick={deleteHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],
            },
        ], [canEdit, canAddOrDelete, editHandler, deleteHandler, downloadHandler]
    )

    const GetApiRef = () => {
        return useGridApiContext();
    }
    const loadingOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the Static Resources' error={error}></ErrorMessage>
                )}
                {!error && (
                    <MainDataGridLoadingSkeleton apiRef={GetApiRef()} rowBottomMargin={6} />
                )}
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the Static Resources' error={error}></ErrorMessage>
                )}
                {!error && (staticFileList && staticFileList.length <= 0) && (
                    <NoRecordsMessage topMargin={6} message="" />
                )}
            </GridOverlay>
        );
    };

    return (
        <MainContentBox>
            <PageTitleBar text='EDI Static Resources' id="static-files-title-comp">
                <PageTitleToolbarGrid item>
                    <FiltersButton
                        onClick={onFiltersClick}
                        filterCount={filterCount}
                        filtersCleared={filtersCleared}
                        disabled={isLoading}
                        aria-label="filter button"
                        data-cy="static-files-filters"
                    />
                    {canAddOrDelete &&
                        <CreateNewButton
                            text="New Resource"
                            onClick={onAddClick}
                            data-cy="add-new-file"
                        />}
                </PageTitleToolbarGrid>
            </PageTitleBar>
            <EDIStaticFilesFilterBar
                id="static-files-filter-bar"
                visible={filtersOpen}
                loading={isLoading}
                viewer={viewer}
                onFilterChanged={refreshFilters}
                onClose={onFilterBarClose}
            />
            <DataGridListScrollBox scrollheight={contentAreaHeight}>
                <MainDataGridNoRowHover
                    loading={isLoading || staticFileList === undefined}
                    headerHeight={38}
                    rowHeight={52}
                    aria-label="EDI Static File List"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={staticFileRows ?? []}
                    columns={staticFileColumns}
                    sortingOrder={['asc', 'desc']}
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    onRowsScrollEnd={handlePageLoad}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />

            </DataGridListScrollBox>
            <EDIStaticFileDialog
                isOpen={openModify}
                resource={selectedStaticFile}
                onClose={onAddEditDialogClose}
                onSave={onAddEditDialogSave}
                error={error}
                viewerRole={viewer?.role}
                isReadOnly={isAddEditDialogReadonlyMode}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedStaticFile?.id ?? ''}
                heading={'Delete Static Resource'}
                message={'Are you sure you want to delete \'' + selectedStaticFile?.description + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </MainContentBox>
    )
}

export default EDIStaticFiles;